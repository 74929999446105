// Instrument
import { types } from './types';
import update from 'immutability-helper';
import { isNil, includes } from "ramda";

/*
Default-ные значения для форм
 */
export const initialState = {
    companyNew: {
        full_name: '',
        business_name: '',
        email: '',
        password: '',
        phone: '',
        country: null,
        currency: 'usd',
        vat: '',
        referral_code: '',
        type: '',
        role: '',
        industry: [],
        challenge: '',
    },
    designerNew: {
        first_name: '',
        last_name: '',
        email: '',
        messengers: '',
        country: '',
        english_level: '',
        position: '',
        employment_status: '',
        design_experience: '',
        current_job: '',
        reason_quit: '',
        why_remote: '',
        expected_salary: '',
        portfolio_links: '',
        sample_websites: '',
        sample_logo: '',
        sample_banners: '',
        sample_illustrations: '',
        photoshop_level: '',
        photoshop_notes: '',
        illustrator_level: '',
        illustrator_notes: '',
        indesign_level: '',
        indesign_notes: '',
        figma_level: '',
        figma_notes: '',
        other_software: '',
        site_constructors: '',
    },
    userNew: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        position: '',
        timezone: '',
        notes: '',
        avatar_url: '',
        user_id: '',
        user_role: '',
        create_date: '',
        last_session: ''
    },
    accountNew: {
        own_balance: 'no',
        account_name: '',
        website: '',
        primary_contact: '',
        primary_contact_phone: '',
        primary_contact_timezone: '',
        notes: '',
        logo_url: '',
        currency: '',
        /* billing */
        billing_address: '',
        billing_city: '',
        billing_company_name: '',
        billing_contact_person: '',
        billing_country_code: '',
        billing_email: '',
        billing_state: '',
        billing_tax_number: '',
        billing_zip: '',
        // client_logins: '',
    },
    writingForm: {
        attachments:              [],
        avoid:                    '',
        brief:                    '',
        content:                  'text',
        copy_style:               'copywriter',
        copy_voice:               'copywriter',
        files:                    [],
        format:                   '',
        industry:                 '',
        invite_writer_active_for: 0,
        invite_writers:           [],
        level:                    '',
        localization:             '',
        mention:                  '',
        order_on:                 null,
        written_by:               null,
        other_industry:           '',
        planned_publish:          '',
        product_list:             [''],
        quantity:                 1,
        similar_content:          '',
        smart_match:              'yes',
        target_audience:          'general',
        topic:                    [''],
        turnaround_custom:        '',
        turnaround_type:          'regular',
        word_count:               0,
        word_count_type:          '',
        project_tags:             [],
    },
    writingSubscriptionForm: {
        attachments:              [],
        files:                    [],
        brief:                    '',
        format:                   '',
        industry:                 '',
        other_industry:           '',
        smart_match:              'yes',
        word_count:               0,
        topic:                    [''],
        product_list:             [''],
        invite_writer_active_for: 0,
        invite_writers:           [],
        quantity:                 1,
        level:                    '',
        localization:             '',
        project_tags:             [],
        copy_style:               'copywriter',
        copy_voice:               'copywriter',
        target_audience:          'general',
        turnaround_custom:        '',
        turnaround_type:          'regular',
        similar_content:          '',
        avoid:                    '',
        mention:                  '',
        content:                  'text',
        order_on:                 null,
        written_by:               null,
    },
    ideasForm: {
        attachments:              [],
        files:                    [],
        brief:                    '',
        content_goal:             '',
        format:                   '',
        idea_count:               0,
        idea_count_type:          '',
        industry:                 '',
        invite_writer_active_for: 0,
        invite_writers:           [],
        level:                    '',
        localization:             '',
        other_industry:           '',
        planned_publish:          '',
        product_service:          '',
        quantity:                 1,
        target_audience:          '',
        topic:                    [''],
        product_list:             [''],
        turnaround_custom:        '',
        turnaround_type:          'regular',
        word_count:               0,
        order_on:                 null,
        written_by:               null,
        smart_match:              'yes',
    },
    proofreadingForm: {
        brief:           '',
        content:         '',
        format:          '',
        industry:        '',
        other_industry:  '',
        level:           '',
        quantity:        1,
        topic:           [''],
        product_list:             [''],
        word_count:               0,
        word_count_type:          '',
        invite_writer_active_for: 0,
        invite_writers:           [],
        turnaround_custom:        '',
        turnaround_type:          'regular',
        project_tags:             [],
        order_on:                 null,
        written_by:               null,
        localization:             '',
        copy_style:               'copywriter',
        copy_voice:               'copywriter',
        smart_match:              'yes',
        attachments:              [],
        files:                    [],
        planned_publish:          '',
        target_audience:          'general',
    },
    keywordsFormOrig: {
        competitors:              '',
        smart_match:              'yes',
        level:                    'standard',
        quantity:        1,
        topic:           [''],
        product_list:    [''],
        brief:                    '',
        additional_details:       '',
        turnaround_custom:        '',
        turnaround_type:          'regular',
        order_on:                 null,
        written_by:               null,
        planned_publish:          '',
        target_audience:          '',
        target_country_code:      '',
        target_location:          '',
        invite_writer_active_for: 0,
        invite_writers:           [],
        attachments:              [],
        files:                    [],
    },
    keywordsForm: {
        competitors:              'main comptetitors test',
        smart_match:              'yes',
        level:                    'standard',
        quantity:        1,
        topic:           ['project topic test keywords'],
        product_list:    [''],
        brief:                    'keywords brief test',
        additional_details:       'additional details test',
        turnaround_custom:        '',
        turnaround_type:          'regular',
        order_on:                 null,
        written_by:               null,
        planned_publish:          '',
        target_audience:          'Target audience test field',
        target_country_code:      '',
        target_location:          'Target location test field',
        invite_writer_active_for: 0,
        invite_writers:           [],
        attachments:              [],
        files:                    [],
    },
    designForm: {
        topic:           [''],
        product_list:    [''],
        format:          '',
        format_options:  [],
        brief:           '',
        level:           'pro',
        quantity:        1,
        design_references:        '',
        format_description:       '',
        format_target_audience:   '',
        format_reason:            '',
        included_text:            '',
        file_types:               [],
        dimensions:               [],
        image_option:             '',
        image_notes:              '',
        image_option_files:       [],
        brand_profiles:           [],
        invite_writer_active_for: 0,
        invite_writers:           [],
        attachments:              [],
        files:                    [],
        smart_match:              'yes',
        workflow:                 [],
        sitemap:                  '',
        used_services:            [],
        review_frequency:         '',
    },
    designSubscriptionForm: {
        topic:           [''],
        product_list:    [''],
        format:          '',
        brief:                    '',
        level:           'pro',
        quantity:        1,
        design_references:        '',
        format_description:       '',
        format_target_audience:   '',
        format_reason:            '',
        included_text:            '',
        file_types:               [],
        dimensions:               [],
        image_option:             '',
        image_notes:              '',
        image_option_files:       [],
        priority:                 'bottom',
        brand_profiles:           [],
        invite_writer_active_for: 0,
        invite_writers:           [],
        attachments:              [],
        files:                    [],
        smart_match:              'yes',
        workflow:                 [],
        sitemap:                  '',
        used_services:            [],
        review_frequency:         '',
    },
    bloggingForm: {
        attachments:              [],
        avoid:                    '',
        brief:                    '',
        content:                  'text',
        copy_style:               'copywriter',
        copy_voice:               'copywriter',
        files:                    [],
        format:                   'blog',
        industry:                 '',
        invite_writer_active_for: 0,
        invite_writers:           [],
        level:                    '',
        localization:             '',
        mention:                  '',
        order_on:                 null,
        written_by:               null,
        other_industry:           '',
        planned_publish:          '',
        product_list:             [''],
        quantity:                 1,
        similar_content:          '',
        smart_match:              'yes',
        target_audience:          'general',
        topic:                    [''],
        turnaround_custom:        '',
        turnaround_type:          'regular',
        word_count:               0,
        word_count_type:          '',
        project_tags:             [],
    },
    bloggingExistingForm: {
        brief:                    '',
        activeIdea:               null,
        format:          '',
        industry:        '',
        other_industry:  '',
        level:           '',
        topic:           [''],
        quantity:        1,
        product_list:             [''],
        smart_match:              'yes',
        word_count:               0,
        idea_number:              null,
        idea_project_id:          null,
        avoid:                    '',
        localization:             '',
        invite_writer_active_for: 0,
        invite_writers:           [],
        attachments:              [],
        files:                    [],
        target_audience:          'general',
        content:                  'text',
        copy_style:               'copywriter',
        copy_voice:               'copywriter',
        order_on:                 null,
        written_by:               null,
    },
    bloggingRequestForm: {
        industry:        '',
        other_industry:  '',
        quantity:        1,
        topic:           [''],
        product_list:             [''],
        format:          'blog',
        product_service:          '',
        target_audience:          '',
        content_goal:             '',
        brief:                    '',
        attachments:              [],
        files:                    [],
        word_count:               0,
        order_on:                 null,
        written_by:               null,
        planned_publish:          '',
        invite_writer_active_for: 0,
        invite_writers:           [],
        idea_count_type: '',
        idea_count: 0,
        level: 'pro',
        smart_match:              'yes',
        turnaround_custom:        '',
        turnaround_type:          'regular',
    },
    bloggingOwnForm: {
        industry:        '',
        other_industry:  '',
        word_count:               0,
        quantity:        1,
        topic:           [''],
        product_list:             [''],
        brief:                    '',
        attachments:              [],
        files:                    [],
        invite_writer_active_for: 0,
        invite_writers:           [],
        order_on:                 null,
        written_by:               null,
        planned_publish:          '',
        localization:             '',
        project_tags:             [],
        copy_style:               'copywriter',
        copy_voice:               'copywriter',
        avoid:                    '',
        content:                  'text',
        mention:                  '',
        target_audience:          'general',
        turnaround_custom:        '',
        turnaround_type:          'regular',
        format:                   'blog',
        smart_match:              'yes',
        similar_content:          '',
    },
    aihForm: {
        attachments:              [],
        avoid:                    '',
        brief:                    '',
        content:                  'text',
        copy_style:               'copywriter',
        copy_voice:               'copywriter',
        files:                    [],
        format:                   '',
        industry:                 '',
        invite_writer_active_for: 0,
        invite_writers:           [],
        level:                    '',
        localization:             '',
        mention:                  '',
        order_on:                 null,
        written_by:               null,
        other_industry:           '',
        planned_publish:          '',
        product_list:             [''],
        quantity:                 1,
        similar_content:          '',
        smart_match:              'yes',
        target_audience:          'general',
        topic:                    [''],
        turnaround_custom:        '',
        turnaround_type:          'regular',
        word_count:               0,
        word_count_type:          '',
        project_tags:             [],
    },
    motionForm: {
        format:          '',
        format_options:  [],
        topic:           [''],
        product_list:    [''],
        attachments:     [],
        logo_file:       [],
        brand_description: '',
        ideas: '',
        idea_attachments:     [],
        idea_files: [],
        examples: '',
        length: '',
        files:                    [],
        file_types:               [],
        dimensions:               [],
        brand_profiles:           [],
        invite_writer_active_for: 0,
        invite_writers:           [],
        review_frequency:         '',
    },
    motionSubscriptionForm: {
        format:          '',
        topic:           [''],
        product_list:    [''],
        attachments:     [],
        logo_file:       [],
        brand_description: '',
        ideas: '',
        idea_attachments:     [],
        idea_files: [],
        examples: '',
        length: '',
        files:                    [],
        file_types:               [],
        dimensions:               [],
        brand_profiles:           [],
        invite_writer_active_for: 0,
        invite_writers:           [],
        review_frequency:         '',
    },
    webauditForm: {
        level:           'standard',
        website: '',
        cms: '',
        google_analytics: '',
        google_search_console: '',
        link_to_resources: '',
        products: '',
        competitors: '',
        target_audience: '',
        goals: '',
        notes: '',
    },
    /*
    seoauditTechnicalForm: {
        website: '',
        seo_audited: '',
        cms: '',
        focus_areas: '',
        monitoring_tools: '',
        competitors: '',
        target_audience: '',
        target_country_code: '',
        goals: '',
        google_access: '',
    },
    seoauditCompetitorForm: {
        website: '',
        competitors: '',
        seo_strategies: '',
        link_analized: '',
        target_audience: '',
        keywords: '',
        keywords_google: '',
        goals: '',
        google_access: '',
    },
    seoauditFullForm: {
        website: '',
        competitors: '',
        target_audience: '',
        target_country_code: '',
        seo_audit_notes: '',
        cms: '',
        focus_areas: '',
        keywords_google: '',
        resources: '',
        marketing_channels: '',
        monitoring_tools: '',
        goals: '',
        google_access: '',
    },
    */
    seobriefFormProd: {
        website: '',
        level: 'standard',
        seobrief_type: 'page',
        brief: '',
        unique_selling: '',
        competitors: '',
        target_location: '',
        target_keywords: '',
        call_to_action: '',
        target_audience: 'general',
        content: 'text',
        copy_style: 'copywriter',
        copy_voice: 'copywriter',
        turnaround_custom: '',
        turnaround_type: 'regular',
        avoid: '',
        additional_details: '',
        order_on: null,
        attachments: [],
        files: [],
    },
    seobriefForm: {
        website: 'website.com',
        level: 'standard',
        seobrief_type: 'page',
        brief: 'here comes some brief',
        unique_selling: 'unique selling optional',
        competitors: 'competitors test',
        target_location: 'taget location test',
        target_keywords: ' target keywords test',
        call_to_action: 'test desired call to action',
        target_audience: 'general',
        content: 'text',
        copy_style: 'copywriter',
        copy_voice: 'copywriter',
        content_reference: 'content reference text here',
        turnaround_custom: '',
        turnaround_type: 'regular',
        avoid: 'What to avoid test',
        additional_details: 'some additional details goes here',
        order_on: null,
        attachments: [],
        files: [],
    },
    contentstrategyForm: {
        website: 'contentstrategy.com',
        level: 'standard',
        brief: 'Primary services or products test',
        unique_selling: 'Unique selling optional test text',
        target_audience: 'general',
        competitors: 'Competitors test text',
        target_location: 'Target location test text',
        target_keywords: 'Target keywords test text',
        content_themes: 'Content themes test text',
        additional_details: 'Additional details test text',
        turnaround_custom: '',
        turnaround_type: 'regular',
        order_on: null,
        attachments: [],
        files: [],
    },
    linkbuildingForm: {
        website: '',
    }
};

export const formsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_FORM_STATE:
            return update(state, {
                [payload.prop]: {$set: payload.value},
            });

        case types.UPDATE_FORM_STATE:
            return update(state, {
                [payload.prop]: {$merge: payload.value},
            });

        case types.UPDATE_FORM_FILES_STATE:
            let data;
            if ( includes(payload.prop, 'files/logo_file/idea_files') ) {
                data = [ ...state[payload.form][payload.prop], ...payload.value ];
            } else {
                if ( isNil(payload.id) ) {
                    data = [ ...state[payload.form][payload.prop], ...payload.value.map(o => ({ file_id: o.id, filename: o.name, size: o.size, url: o.url })) ];
                } else {
                    data = [ ...state[payload.form][payload.prop].filter(o => o.file_id !== payload.id), ...payload.value ];
                }
            }
            return update(state, {
                [payload.form]: {
                    [payload.prop]: {$set: data},
                },
            });

        case types.CLEAR_ORDER_FORMS:
            return update(state, {
                writingForm:             {$set: initialState.writingForm},
                writingSubscriptionForm: {$set: initialState.writingSubscriptionForm},
                ideasForm:               {$set: initialState.ideasForm},
                proofreadingForm:        {$set: initialState.proofreadingForm},
                keywordsForm:            {$set: initialState.keywordsForm},
                designForm:              {$set: initialState.designForm},
                designSubscriptionForm:  {$set: initialState.designSubscriptionForm},
                bloggingForm:            {$set: initialState.bloggingForm},
                bloggingExistingForm:    {$set: initialState.bloggingExistingForm},
                bloggingRequestForm:     {$set: initialState.bloggingRequestForm},
                bloggingOwnForm:         {$set: initialState.bloggingOwnForm},
                aihForm:                 {$set: initialState.aihForm},
                motionForm:              {$set: initialState.motionForm},
                motionSubscriptionForm:  {$set: initialState.motionSubscriptionForm},
                webauditForm:            {$set: initialState.webauditForm},
                // seoauditTechnicalForm:   {$set: initialState.seoauditTechnicalForm},
                // seoauditCompetitorForm:  {$set: initialState.seoauditCompetitorForm},
                // seoauditFullForm:        {$set: initialState.seoauditFullForm},
                seobriefForm:            {$set: initialState.seobriefForm},
                contentstrategyForm:     {$set: initialState.contentstrategyForm},
                linkbuildingForm:        {$set: initialState.linkbuildingForm}
            });

        default:
            return state;
    }
};
