// Core
import {useLayoutEffect} from "react";

// Hooks

// Instruments
import {initDefaultRedactor, getOrderForm} from "../../../helpers/helpers";

export const SeoBrief = ({fields}) => {

    /* Hooks */
    const {
        getRateByJobTypeField,
        getSeoBriefWebsiteField,
        getSeoBriefTypeField,
        getSeoBriefDescription,
        getTextInputField,
        getTargetLocationField,
        getCopyStyleVoiceField,
        getTargetAudienceContentFields,
        getAdditionDetailsField,
        getTurnaroundByJobTypeField,
        getOrderOnField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;

    useLayoutEffect(() => {
        initDefaultRedactor('#brief-textarea', 'brief', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#additional_details-textarea', 'additional_details', '116px', null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#brief-textarea', 'destroy');
            window.$R('#additional_details-textarea', 'destroy');
        };
    }, []);


    return <>
        {getRateByJobTypeField()}
        {getSeoBriefWebsiteField()}
        {getSeoBriefTypeField()}
        {getSeoBriefDescription(false)}
        {getTextInputField('unique_selling', 'Unique selling proposition of the primary service or product (optional)')}
        {getTextInputField('competitors', 'Main competitor websites')}
        {getTargetLocationField()}
        {getTextInputField('target_keywords', 'Target keyword examples')}
        {getTextInputField('call_to_action', 'Desired call to action')}
        {getCopyStyleVoiceField(null)}
        {getTargetAudienceContentFields()}
        {getTextInputField('avoid', 'What to avoid')}
        {getTextInputField('content_reference', 'Content reference (what you like)')}
        {getAdditionDetailsField(true)}
        {getTurnaroundByJobTypeField()}
        { getOrderOnField() }
    </>;
};
